import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputRange from 'react-input-range';
import dayjs from 'dayjs';
import axiosAuth from '../../../utils/axiosAuth';
import roundNumber from '../../../utils/roundNumber';
import durationToString from '../../../utils/durationToString';
import samLogo from '../../../assets/logos/sam.svg';
import Button from '../../common/button/Button';
import AirbagLoader from '../../common/airbag-loader/AirbagLoader';
import Error from '../../common/error/Error';
import './Game.scss';

function Game(props) {
  const { user, company } = props;
  const MAX_KM_RANGE = 15000;
  const [error, setError] = useState(null);
  const [dateFilter, setDateFilter] = useState(dayjs().format('YYYY-MM'));
  const [activeDriversFilter, setActiveDriversFilter] = useState('all');
  const [managerInfo, setManagerInfo] = useState({
    lastLogin: '',
    pageViews: 0,
    timeOnPlatform: 0
  });
  const [drivers, setDrivers] = useState(null);
  const [activeDrivers, setActiveDrivers] = useState(null);
  const [activeDriversKm, setActiveDriversKm] = useState(100);
  const [newDrivers, setNewDrivers] = useState(null);
  const [riskDrivers, setRiskDrivers] = useState(null);
  const [coinsHistory, setCoinsHistory] = useState(null);

  useEffect(() => {
    setDrivers(null);
    setActiveDrivers(null);
    setNewDrivers(null);
    setRiskDrivers(null);
    setCoinsHistory(null);

    // Get drivers status
    axiosAuth
      .get(`/api/game/dashboard`, { params: { date: dateFilter } })
      .then((res) => {
        const {
          drivers: driversRes,
          coinsHistory: coinsHistoryRes,
          managerInfo: managerInfoRes
        } = res.data;
        setManagerInfo(managerInfoRes);
        setDrivers(driversRes);
        setActiveDrivers(
          buildActiveDriversArray(driversRes, activeDriversFilter)
        );
        setNewDrivers(
          driversRes.filter(
            (d) =>
              dayjs(d.created).format('YYYY-MM') ===
              dayjs(dateFilter).format('YYYY-MM')
          )
        );
        setRiskDrivers(buildRiskDriversArray(driversRes));
        setCoinsHistory(coinsHistoryRes);
      })
      .catch((err) => {
        setError(err);
        console.log(err);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFilter]);

  useEffect(() => {
    if (drivers) {
      setActiveDrivers(buildActiveDriversArray(drivers, activeDriversFilter));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDriversKm, activeDriversFilter]);

  function distancePerc(target, value) {
    if (!target) {
      return 0;
    }

    return roundNumber((value * 100) / target);
  }

  function renderActiveDriversKPI() {
    if (!drivers) {
      return 0;
    } else {
      let count = 0;
      for (const driver of drivers) {
        if (driver.distance > activeDriversKm) {
          count++;
        }
      }
      return count;
    }
  }

  function buildActiveDriversArray(drivers, filter) {
    const ret = [];

    for (const driver of drivers) {
      // 1. Make sure user existed before date filter
      // 2. Users must be active
      // 3. User must have logged in
      if (
        dayjs(driver.created).format('YYYY-MM') <= dateFilter &&
        driver.status === 'active' &&
        !driver.firstLogin
      ) {
        if (filter === 'active') {
          // Active drivers
          if (distancePerc(activeDriversKm, driver.distance) >= 100) {
            ret.push(driver);
          }
        } else if (filter === 'inactive') {
          // Inactive drivers
          if (distancePerc(activeDriversKm, driver.distance) < 100) {
            ret.push(driver);
          }
        } else {
          // All drivers
          ret.push(driver);
        }
      }
    }

    return ret;
  }

  function buildRiskDriversArray(drivers) {
    const ret = [];

    for (const driver of drivers) {
      let comment = '';
      let icon = '';

      // Discard users that where created before date filter
      if (dayjs(driver.created).format('YYYY-MM') > dateFilter) {
        continue;
      }

      // Discard users that are inactive
      if (driver.status === 'inactive') {
        continue;
      }

      if (driver.distance || driver.firstLogin) {
        if (driver.firstLogin) {
          comment = 'Sin descarga';
          icon = 'mobile-button';
        } else if (
          driver.score < 60 ||
          driver.acceleration < 60 ||
          driver.braking < 60 ||
          driver.cornering < 60 ||
          driver.speeding < 60 ||
          driver.phoneUsage < 60
        ) {
          comment = 'Calificaciones de riesgo';
          icon = 'hundred-points';
        }
      } else if (!driver.isResting) {
        comment = 'Sin distancia registrada';
        icon = 'truck';
      }

      if (comment) {
        driver.riskComment = comment;
        driver.commentIcon = icon;
        ret.push(driver);
      }
    }

    return ret;
  }

  function handleScoreColor(number) {
    if (number >= 80) {
      return 'good';
    } else if (number >= 60) {
      return 'regular';
    } else if (number > 0 && number < 60) {
      return 'bad';
    }
    return 'na';
  }

  function renderCoinAdjustmentReason(reason) {
    let ret = 'Otro';

    if (reason === 'bonus') {
      ret = 'Bono';
    }

    return ret;
  }

  function renderCoinDiff(diff) {
    if (diff > 0) {
      return <span className="add">+{diff}</span>;
    } else {
      return <span className="subtract">{diff}</span>;
    }
  }

  function checkMinDate() {
    const companyCreated = dayjs(company.created);
    const oneYearAgo = dayjs().subtract(1, 'y');

    // Return company creation date if was created less than a year ago
    if (companyCreated.isAfter(oneYearAgo)) {
      return companyCreated.format('YYYY-MM');
    }

    // Limit to 1 year in past
    return oneYearAgo.format('YYYY-MM');
  }

  function renderProfileCard() {
    const { name, lastName, email, phone, coins } = user;

    return (
      <div id="profile-card" className="g-card">
        <div>
          <span className="bold">Mi perfil</span>
          <span id="profile-coins">
            <FontAwesomeIcon icon={['fal', 'coin-blank']} />
            {coins || 0}
          </span>
        </div>
        <div id="photo">
          <img src={samLogo} alt="Airbag Technologies" id="sam-logo" />
        </div>
        <p id="name">
          {name} {lastName}
        </p>
        <table>
          <tbody>
            <tr>
              <td className="bold">Correo</td>
              <td>{email}</td>
            </tr>
            <tr>
              <td className="bold">Teléfono</td>
              <td>{phone}</td>
            </tr>
            <tr>
              <td>
                <span className="divider-g"></span>
              </td>
              <td>
                <span className="divider-g"></span>
              </td>
            </tr>
            <tr>
              <td className="bold">Último ingreso</td>
              <td>
                {managerInfo.lastLogin
                  ? dayjs(managerInfo.lastLogin).format(
                      'DD [de] MMMM [de] YYYY'
                    )
                  : 'No disponible'}
              </td>
            </tr>
            <tr>
              <td className="bold">Páginas visitadas</td>
              <td>
                {managerInfo.pageViews
                  ? managerInfo.pageViews
                  : 'No disponible'}
              </td>
            </tr>
            <tr>
              <td className="bold">Tiempo en plataforma</td>
              <td>
                {managerInfo.timeOnPlatform
                  ? durationToString(
                      dayjs.duration(managerInfo.timeOnPlatform, 'm')
                    )
                  : 'No disponible'}
              </td>
            </tr>
          </tbody>
        </table>
        <div id="edit-link">
          {!user.isInvitee ? (
            <Link to="/perfil">
              <Button variant="yellow-button" type="button">
                Editar Perfil
              </Button>
            </Link>
          ) : (
            <Link to="/conductores">
              <Button variant="yellow-button" type="button">
                Ver conductores
              </Button>
            </Link>
          )}
        </div>
      </div>
    );
  }

  function renderTopKPIs() {
    return (
      <div className="row" id="top-kpis">
        <div className="col s12 m4">
          <div className="top-card">
            <div className="inner-top-card">
              <FontAwesomeIcon icon={['fal', 'users']} id="au" />
              <span>{drivers ? drivers.length : 0}</span>
              <p>Total de usuarios</p>
            </div>
          </div>
        </div>
        <div className="col s12 m4">
          <div className="top-card">
            <div className="inner-top-card">
              <FontAwesomeIcon icon={['fal', 'truck']} id="ad" />
              <span>{renderActiveDriversKPI()}</span>
              <p>Usuarios activos</p>
            </div>
          </div>
        </div>
        <div className="col s12 m4">
          <div className="top-card">
            <div className="inner-top-card">
              <FontAwesomeIcon icon={['fal', 'user-plus']} id="nd" />
              <span>{newDrivers ? newDrivers.length : 0}</span>
              <p>Nuevos usuarios</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function renderActiveDriversFilters() {
    return (
      <>
        <div className="date-filter">
          <input
            type="month"
            name="start"
            max={dayjs().format('YYYY-MM')}
            min={checkMinDate()}
            value={dateFilter}
            className="ranking-month-filter"
            onChange={(e) => setDateFilter(e.target.value)}
          />
        </div>
        <div className="select-filter">
          <select
            className="browser-default"
            value={activeDriversFilter}
            onChange={(e) => {
              setActiveDriversFilter(e.target.value);
            }}
          >
            <option value="all">Todos</option>
            <option value="active">Cumple</option>
            <option value="inactive">No cumple</option>
          </select>
        </div>
        <div id="ad-range-filter">
          <label className="active">Rango de km</label>
          <InputRange
            id="activeDriversRange"
            maxValue={MAX_KM_RANGE}
            minValue={100}
            formatLabel={(value) => `${value} km`}
            value={activeDriversKm}
            step={100}
            onChange={(value) => {
              if (value >= 100 && value <= MAX_KM_RANGE) {
                setActiveDriversKm(value);
              }
            }}
          />
        </div>
      </>
    );
  }

  function renderActiveUsersCard() {
    // Loading
    if (!activeDrivers) {
      return (
        <div id="loader-container">
          <AirbagLoader />
        </div>
      );
    }

    // No active drivers
    if (!activeDrivers.length) {
      return (
        <div className="airbag-table-container">
          <div className="airbag-top" id="ad-top">
            <span className="bold">Nivel de uso por conductor</span>
            {renderActiveDriversFilters()}
          </div>
          <table className="airbag-table">
            <tbody>
              <tr>
                <td className="center-align">
                  <FontAwesomeIcon
                    icon={['fal', 'users-slash']}
                    className="no-data"
                  />
                  <p>Sin conductores</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    }

    return (
      <div className="airbag-table-container">
        <div className="airbag-top" id="ad-top">
          <span className="bold">Nivel de uso por conductor</span>
          {renderActiveDriversFilters()}
        </div>
        <table className="airbag-table" id="active-drivers-table">
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Actividad</th>
              <th className="center-align">Completado</th>
            </tr>
          </thead>
          <tbody>
            {activeDrivers.map((driver, i) => {
              return (
                <tr key={driver.userId} className="table-row">
                  <td>
                    <span className="pos-index">{i + 1}.</span>
                    <Link to={`/conductor?driverId=${driver.userId}`}>
                      {driver.fullName}
                    </Link>
                  </td>
                  <td>
                    <progress
                      value={distancePerc(activeDriversKm, driver.distance)}
                      max="100"
                    >
                      {distancePerc(activeDriversKm, driver.distance)} %
                    </progress>
                  </td>
                  <td className="center-align">
                    {distancePerc(activeDriversKm, driver.distance)} %
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  function renderNewDrivers() {
    // Loading
    if (!newDrivers) {
      return (
        <div id="loader-container">
          <AirbagLoader />
        </div>
      );
    }

    // No new drivers
    if (!newDrivers.length) {
      return (
        <div className="airbag-table-container">
          <div className="airbag-top">
            <span className="bold">Nuevos usuarios</span>
            <div className="date-filter">
              <input
                type="month"
                name="start"
                max={dayjs().format('YYYY-MM')}
                min={checkMinDate()}
                value={dateFilter}
                className="ranking-month-filter"
                onChange={(e) => setDateFilter(e.target.value)}
              />
            </div>
          </div>
          <table className="airbag-table">
            <tbody>
              <tr>
                <td className="center-align">
                  <FontAwesomeIcon
                    icon={['fal', 'users-slash']}
                    className="no-data"
                  />
                  <p>Sin nuevos usuarios</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    }

    return (
      <div className="airbag-table-container">
        <div className="airbag-top">
          <span className="bold">Nuevos usuarios</span>
          <div className="date-filter">
            <input
              type="month"
              name="start"
              max={dayjs().format('YYYY-MM')}
              min={checkMinDate()}
              value={dateFilter}
              className="ranking-month-filter"
              onChange={(e) => setDateFilter(e.target.value)}
            />
          </div>
        </div>
        <table className="airbag-table" id="active-drivers-table">
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Fecha de alta</th>
            </tr>
          </thead>
          <tbody>
            {newDrivers.map((driver, i) => {
              return (
                <tr key={driver.userId} className="table-row">
                  <td>
                    <span className="pos-index">{i + 1}.</span>
                    <Link to={`/conductor?driverId=${driver.userId}`}>
                      {driver.fullName}
                    </Link>
                  </td>
                  <td>{dayjs(driver.created).format('DD [de] MMMM')}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  function renderRiskDrivers() {
    // Loading
    if (!riskDrivers) {
      return (
        <div id="loader-container">
          <AirbagLoader />
        </div>
      );
    }

    // No drivers in risk
    if (!riskDrivers.length) {
      return (
        <div className="airbag-table-container">
          <div className="airbag-top">
            <span className="bold">Conductores en riesgo</span>
            <div className="date-filter">
              <input
                type="month"
                name="start"
                max={dayjs().format('YYYY-MM')}
                min={checkMinDate()}
                value={dateFilter}
                className="ranking-month-filter"
                onChange={(e) => setDateFilter(e.target.value)}
              />
            </div>
          </div>
          <table className="airbag-table">
            <tbody>
              <tr>
                <td className="center-align">
                  <FontAwesomeIcon
                    icon={['fal', 'users-slash']}
                    className="no-data"
                  />
                  <p>Sin conductores</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    }

    return (
      <div className="airbag-table-container">
        <div className="airbag-top">
          <span className="bold">Conductores en riesgo</span>
          <div className="date-filter">
            <input
              type="month"
              name="start"
              max={dayjs().format('YYYY-MM')}
              min={checkMinDate()}
              value={dateFilter}
              className="ranking-month-filter"
              onChange={(e) => setDateFilter(e.target.value)}
            />
          </div>
        </div>
        <table className="airbag-table" id="active-drivers-table">
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Observaciones</th>
              <th>Calificación</th>
              <th>Desglose por hábito</th>
            </tr>
          </thead>
          <tbody>
            {riskDrivers.map((driver, i) => {
              return (
                <tr key={driver.userId} className="table-row">
                  <td>
                    <span className="pos-index">{i + 1}.</span>
                    <Link to={`/conductor?driverId=${driver.userId}`}>
                      {driver.fullName}
                    </Link>
                  </td>
                  <td>
                    <FontAwesomeIcon
                      className="comment-icon"
                      icon={['fal', driver.commentIcon]}
                    />
                    <span>{driver.riskComment}</span>
                  </td>
                  <td className="scores">
                    <span
                      className={`overall-score score-item ${handleScoreColor(
                        roundNumber(driver.score)
                      )}`}
                    >
                      {driver.score ? roundNumber(driver.score) : '-'}
                    </span>
                  </td>
                  <td className="scores">
                    <span className="scores-icons">
                      <span
                        className={`score-item ${handleScoreColor(
                          driver.phoneUsage
                        )}`}
                      >
                        <FontAwesomeIcon icon={['fal', 'mobile-button']} />
                      </span>
                      <span
                        className={`score-item ${handleScoreColor(
                          driver.speeding
                        )}`}
                      >
                        <FontAwesomeIcon icon={['fal', 'gauge-max']} />
                      </span>
                      <span
                        className={`score-item ${handleScoreColor(
                          driver.cornering
                        )}`}
                      >
                        <FontAwesomeIcon icon={['fal', 'diamond-turn-right']} />
                      </span>
                      <span
                        className={`score-item ${handleScoreColor(
                          driver.acceleration
                        )}`}
                      >
                        <FontAwesomeIcon icon={['fal', 'gauge-simple-max']} />
                      </span>
                      <span
                        className={`score-item ${handleScoreColor(
                          driver.braking
                        )}`}
                      >
                        <FontAwesomeIcon icon={['fal', 'gauge-simple-min']} />
                      </span>
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  function renderCoinsHistory() {
    // Loading
    if (!coinsHistory) {
      return (
        <div id="loader-container">
          <AirbagLoader />
        </div>
      );
    }

    // No coins adjustments
    if (!coinsHistory.length) {
      return (
        <div className="airbag-table-container">
          <div className="airbag-top">
            <span className="bold">Historial de monedas</span>
          </div>
          <table className="airbag-table">
            <tbody>
              <tr>
                <td className="center-align">
                  <FontAwesomeIcon
                    icon={['fal', 'coin-blank']}
                    className="no-data"
                  />
                  <p>Sin movimientos</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    }

    return (
      <div className="airbag-table-container">
        <div className="airbag-top">
          <span className="bold">Historial de monedas</span>
        </div>
        <table className="airbag-table" id="coins-history-table">
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Fecha de movimiento</th>
              <th>Monedas iniciales</th>
              <th>Diferencia</th>
              <th>Monedas finales</th>
            </tr>
          </thead>
          <tbody>
            {coinsHistory.map((adjustment, i) => {
              return (
                <tr key={adjustment.id} className="table-row">
                  <td>
                    <span className="pos-index">{i + 1}.</span>
                    {renderCoinAdjustmentReason(adjustment.reason)}
                  </td>
                  <td>{dayjs(adjustment.created).format('DD [de] MMMM')}</td>
                  <td>{adjustment.oldCoins}</td>
                  <td>{renderCoinDiff(adjustment.diff)}</td>
                  <td className="bold">{adjustment.newCoins}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  // API error handler
  if (error) {
    return <Error error={error} />;
  }

  return (
    <div id="_company-game_">
      <div className="row no-margin">
        <div className="col s12 l4">{renderProfileCard()}</div>
        <div className="col s12 l8">
          {renderTopKPIs()}
          <div id="active-drivers-card">{renderActiveUsersCard()}</div>
        </div>
      </div>
      <div className="row no-margin">
        <div className="col s12 l4">{renderNewDrivers()}</div>
        <div className="col s12 l8">
          <div id="risk-drivers-cards">{renderRiskDrivers()}</div>
        </div>
        <div className="col s12">
          <div id="coins-history-card">{renderCoinsHistory()}</div>
        </div>
      </div>
    </div>
  );
}

export default Game;
