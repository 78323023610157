import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const formatPrice = (price) => {
  return new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(price);
};

export const GeneralBillingInfo = ({
  billing,
  deletedBilling,
  prorated,
  proratedTotal,
  nonBillable
}) => {
  return (
    <div className="general-billing-container">
      <div className="general-billing-item kpi-container">
        <p className="kpi"></p>
        <span className="kpi">Cantidad</span>
        <span className="kpi">Total</span>
      </div>
      <div className="general-billing-item kpi-container">
        <p className="kpi">
          <FontAwesomeIcon
            icon={['fal', 'money-check-dollar']}
            className="kpi-icon"
          />
          <span>Usuarios cobrables completos</span>
        </p>
        <span>{billing}</span>
        <span>${formatPrice(billing * 200)}</span>
      </div>
      <div className="general-billing-item kpi-container">
        <p className="kpi">
          <FontAwesomeIcon icon={['fal', 'user-tag']} className="kpi-icon" />
          <span>Usuarios con prorateo</span>
        </p>
        <span>{prorated}</span>
        <span>${formatPrice(proratedTotal.toFixed(2))}</span>
      </div>
      <div className="general-billing-item kpi-container">
        <p className="kpi">
          <FontAwesomeIcon icon={['fa', 'user-slash']} className="kpi-icon" />
          <span>Usuarios eliminados cobrables</span>
        </p>
        <span>{deletedBilling}</span>
        <span>${formatPrice(deletedBilling * 200)}</span>
      </div>
      <div className="general-billing-item kpi-container">
        <p className="kpi">
          <FontAwesomeIcon
            icon={['fal', 'money-check-dollar']}
            className="kpi-icon"
          />
          <span>Usuarios no cobrables</span>
        </p>
        <span>{nonBillable}</span>
        <span>${formatPrice(0)}</span>
      </div>

      <div className="general-billing-item kpi-container">
        <p className="kpi"></p>
        <span></span>
        <span>
          Total $
          {formatPrice(
            ((deletedBilling + billing) * 200 + proratedTotal).toFixed(2)
          )}
        </span>
      </div>

      <span className="price-per-user">*Costo por usuario: $200.00</span>
    </div>
  );
};
