import React from 'react';
import DoughnutChart from '../../common/chartjs/DoughnutChart';

function Graphs(props) {
  const { currentLogsData, pastLogsData, period, current, past } = props;

  // Handle period name
  function periodName(capitalize) {
    let ret = 'periodo';

    if (period === 'weekly') ret = 'semana';
    if (period === 'biweekly') ret = 'quincena';
    if (period === 'monthly') ret = 'mes';

    // Check if we have to capitalize
    if (capitalize) ret = ret.charAt(0).toUpperCase() + ret.slice(1);

    // Return period name
    return ret;
  }

  function currentPeriodText(current) {
    if (!current) {
      return '-';
    }

    // Get only month and day
    const startRaw = current.start.slice(5, 10).split('-');
    // Invert them to get DD/MM format
    const start = startRaw[1] + '/' + startRaw[0];

    // Get only month and day
    const endRaw = current.end.slice(5, 10).split('-');
    // Invert them to get DD/MM format
    const end = endRaw[1] + '/' + endRaw[0];

    return `${start} - ${end}`;
  }

  function pastPeriodText(past) {
    if (!past) {
      return '-';
    }

    const startRaw = past.start.slice(5, 10).split('-');
    const start = startRaw[1] + '/' + startRaw[0];

    const endRaw = past.end.slice(5, 10).split('-');
    const end = endRaw[1] + '/' + endRaw[0];

    return `${start} - ${end}`;
  }

  if (currentLogsData || pastLogsData) {
    return (
      <div className="row no-margin">
        <div className="col s12">
          <div className="row graphs-container">
            <div className="col s12">
              <p className="b-title">Frecuencia de datos generados</p>
            </div>
            <div className="col s12 m6 l6">
              <p className="custom-chart-title">
                {periodName(true)} actual <br />
                {currentPeriodText(current)}
              </p>
              <div className="doughnut-chart">
                {currentLogsData ? (
                  <DoughnutChart
                    labels={currentLogsData.labels}
                    data={currentLogsData.data}
                    colors={currentLogsData.colors}
                  />
                ) : (
                  <p className="no-data">Sin información</p>
                )}
              </div>
            </div>
            <div className="col s12 m6 l6">
              <p className="custom-chart-title">
                {periodName(true)} anterior <br />
                {pastPeriodText(past)}
              </p>
              <div className="doughnut-chart">
                {pastLogsData ? (
                  <DoughnutChart
                    labels={pastLogsData.labels}
                    data={pastLogsData.data}
                    colors={pastLogsData.colors}
                  />
                ) : (
                  <p className="no-data">Sin información</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return null;
}

export default Graphs;
