import React, { useState, useEffect, useMemo, useCallback } from 'react';
import AirbagLoader from '../../common/airbag-loader/AirbagLoader';
import axiosAuth from '../../../utils/axiosAuth';
import './Billing.scss';
import dayjs from 'dayjs';
import { BillingItem } from './BillingItem';
import HeaderSection from '../../common/header-section/HeaderSection';
import { GeneralBillingInfo } from './GeneralBillingInfo';

const today = dayjs();
const getProratedAmount = (days) => {
  const daysInMonth = today.daysInMonth();
  const remainingDays = daysInMonth - days;

  return (200 / daysInMonth) * remainingDays;
};

export const Billing = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [drivers, setDrivers] = useState(null);
  const [billable, setBillable] = useState(0);
  const [deletedBillable, setDeletedBillable] = useState(0);
  const [proratedTotal, setProratedTotal] = useState(0);
  const [prorated, setProrated] = useState(0);
  const [nonBillable, setNonBillable] = useState(0);
  const [sortKey, setSortKey] = useState('');
  const [sortOrder, setSortOrder] = useState(false);

  const fetchDrivers = async () => {
    try {
      const { data } = await axiosAuth.get('/api/billing');

      setDrivers(data.users);
      setProratedTotal(
        // Iterate through the drivers to get the total of prorated
        data.users.reduce((acc, curr) => {
          if (curr.proratable || curr.user.proratable) {
            return (
              acc + getProratedAmount(curr.proratable || curr.user.proratable)
            );
          } else {
            return acc;
          }
        }, 0)
      );
      setProrated(data.numberOfProratable);
      setNonBillable(data.numberOfNonBillable);
      setBillable(data.numberOfBillable);
      setDeletedBillable(data.numberOfDeletedBillable);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Function to sort drivers by key clicked
  // used 'useCallback' since it is a complex function
  const sortDrivers = useCallback((drivers, key) => {
    if (!drivers) return [];
    switch (key) {
      case 'name':
        return [...drivers]?.sort((a, b) => {
          if (a.user.fullName < b.user.fullName) {
            return -1;
          }
          if (a.user.fullName > b.user.fullName) {
            return 1;
          }
          return 0;
        });
      case 'firstLoginDate':
        return [...drivers]?.sort((a, b) => {
          if (
            a.loginHistory[0] &&
            b.loginHistory[0] &&
            dayjs(a.loginHistory[0].created).isBefore(
              dayjs(b.loginHistory[0].created)
            )
          ) {
            return -1;
          }
          if (
            a.loginHistory[0] &&
            b.loginHistory[0] &&
            dayjs(b.loginHistory[0].created).isBefore(
              dayjs(a.loginHistory[0].created)
            )
          ) {
            return 1;
          }

          if (!a.loginHistory[0] && b.loginHistory[0]) {
            return 1;
          }

          if (a.loginHistory[0] && !b.loginHistory[0]) {
            return -1;
          }

          return 0;
        });
      case 'hoursSinceFirstLogin':
        return [...drivers]?.sort((a, b) => {
          if (
            a.loginHistory[0] &&
            b.loginHistory[0] &&
            today.diff(dayjs(a.loginHistory[0].created), 'hours') <
              today.diff(dayjs(b.loginHistory[0].created), 'hours')
          ) {
            return -1;
          }
          if (
            a.loginHistory[0] &&
            b.loginHistory[0] &&
            today.diff(dayjs(a.loginHistory[0].created), 'hours') >
              today.diff(dayjs(b.loginHistory[0].created), 'hours')
          ) {
            return 1;
          }
          if (!a.loginHistory[0] && !b.loginHistory[0]) {
            return 1;
          }
          return 0;
        });
      case 'distance':
        return [...drivers]?.sort((a, b) => {
          return !!a.user.currentLevel?.currentDistance ===
            !!b.user.currentLevel?.currentDistance
            ? 0
            : a.user.currentLevel?.currentDistance
            ? -1
            : 1;
        });
      case 'billable':
        return [...drivers]?.sort((a, b) => {
          const aBillable = a.billable ?? a.user?.billable ?? false;
          const bBillable = b.billable ?? b.user?.billable ?? false;

          if (aBillable === bBillable) return 0;
          return aBillable ? -1 : 1;
        });
      case 'billingType':
        return [...drivers]?.sort((a, b) => {
          const aType =
            a.proratable || a.user.proratable
              ? 1
              : a.billable || a.user.billable
              ? 2
              : 3;
          const bType =
            b.proratable || b.user.proratable
              ? 1
              : b.billable || b.user.billable
              ? 2
              : 3;
          return aType - bType;
        });
      case 'deleted':
        return [...drivers]?.sort((a, b) =>
          a.user.deleted === b.user.deleted ? 0 : a.user.deleted ? -1 : 1
        );

      case 'deletedDate':
        return [...drivers]?.sort((a, b) => {
          if (
            a.user.deleted &&
            b.user.deleted &&
            dayjs(a.user.deletedDate).isBefore(dayjs(b.user.deletedDate))
          ) {
            return -1;
          }
          if (
            a.user.deleted &&
            b.user.deleted &&
            dayjs(b.user.deletedDate).isBefore(dayjs(a.user.deletedDate))
          ) {
            return 1;
          }
          if (!a.user.deleted || !b.user.deleted) {
            return -1;
          }
          return 0;
        });
      default:
        return drivers;
    }
  }, []);

  useEffect(() => {
    // call fetchDrivers when component mounts
    fetchDrivers();
  }, []);

  const handleHeaderClick = async (event) => {
    const { target } = event;

    if (target.getAttribute('data-sort') !== sortKey) {
      setSortKey(target.getAttribute('data-sort'));
      setSortOrder(false);
    } else {
      // reverse the direction of the array
      setSortOrder(!sortOrder);
    }
  };

  // use memoization to prevent unwanted re-renders
  const sortedDrivers = useMemo(() => {
    const sorted = sortDrivers(drivers, sortKey)?.map((driver, idx) => (
      <BillingItem {...driver} key={idx} idx={idx} />
    ));
    return sortOrder ? sorted.reverse() : sorted;
  }, [drivers, sortKey, sortOrder, sortDrivers]);

  if (isLoading) {
    return <AirbagLoader />;
  }

  if (!drivers || drivers.length === 0) {
    return (
      <div className="airbag-table-container no-drivers">
        <p>Sin conductores</p>
      </div>
    );
  }

  return (
    <div id="_company-billing_">
      <HeaderSection title="Facturación del mes" />
      <div className="row card-custom main-card">
        <GeneralBillingInfo
          billing={billable}
          deletedBilling={deletedBillable}
          prorated={prorated}
          proratedTotal={proratedTotal}
          nonBillable={nonBillable}
        />
      </div>
      <div className="airbag-table-container" id="companies-table">
        <table className="airbag-table">
          <thead>
            <tr>
              <th>
                <span onClick={handleHeaderClick} data-sort="name">
                  Nombre
                </span>
              </th>
              <th className="center-align">
                <span onClick={handleHeaderClick} data-sort="firstLoginDate">
                  Primer inicio de sesión
                </span>
              </th>
              <th className="center-align">
                <span onClick={handleHeaderClick} data-sort="billable">
                  Cobrable
                </span>
              </th>
              <th className="center-align">
                <span onClick={handleHeaderClick} data-sort="billingType">
                  Tipo de cobro
                </span>
              </th>
              <th className="center-align">
                <span onClick={handleHeaderClick} data-sort="deleted">
                  Eliminado
                </span>
              </th>
              <th className="center-align">
                <span onClick={handleHeaderClick} data-sort="deletedDate">
                  Fecha de eliminación
                </span>
              </th>
            </tr>
          </thead>
          <tbody>{sortedDrivers}</tbody>
        </table>
      </div>
    </div>
  );
};
