import React, { useState, useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import M from 'materialize-css';
import axiosAuth from '../../../utils/axiosAuth';
import { isManagerInParentCompany } from '../../../utils/companyGroupStates';
import SideModal from '../../common/side-modal/SideModal';
import CompanyNotifications from '../notifications/CompanyNotifications';
import ProfileDropdown from './profile-dropdown/ProfileDropdown';
import './CompanySidenav.scss';

function CompanySidenav(props) {
  const {
    user,
    company,
    sidenavNotCollapsed,
    setSidenavNotCollapsed,
    history,
    companyGroup,
    skin
  } = props;
  const [showSidenav, setShowSidenav] = useState(false);
  const [showCourtain, setShowCourtain] = useState(false);
  const [notifOpen, setNotifOpen] = useState(false);
  const [hasUnreadNotifs, setHasUnreadNotifs] = useState(false);

  useEffect(() => {
    // Get if there are unread notifications
    if (!notifOpen && !user.isSam && !user.isInvitee) {
      axiosAuth
        .get(`/api/managers/notifications/has-unread`)
        .then((res) => {
          const { hasUnread } = res.data;
          setHasUnreadNotifs(hasUnread);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifOpen]);

  function toggleSidenav(value) {
    // Add or remove body scroll
    if (value) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    setShowSidenav(value);
    setShowCourtain(value);
  }

  function handleCourtainClick() {
    document.body.classList.remove('no-scroll');
    setShowSidenav(false);
    setShowCourtain(false);
  }

  function handleSidenavClick() {
    // On mobile we need to close the sidenav
    if (setShowCourtain) {
      // We show the courtain only on small screens (< 993px)
      setShowSidenav(false);
      setShowCourtain(false);

      // Remove scroll blocker
      document.body.classList.remove('no-scroll');
    }
  }

  function registerOpenXRay() {
    // Register x-ray opened
    axiosAuth
      .post('/api/web-actions/opened-xray')
      .then(() => {})
      .catch((err) => {
        console.log(err);
      });
  }

  function handleCompanychange(newCompanyId) {
    // User selected same company
    if (newCompanyId === company.id) {
      return;
    }

    axiosAuth
      .get('/api/auth/company-token', {
        params: { newCompanyId }
      })
      .then((res) => {
        const { accessToken } = res.data;

        // Handle domain
        let domainCookie = `.${skin.domain}`;
        let domainRedirect = `https://app.${skin.domain}`;
        if (process.env.REACT_APP_ENV === 'development') {
          domainCookie = 'localhost';
          domainRedirect = 'http://localhost:3000';
        }
        // Set cookie
        props.cookies.set('aat', accessToken, {
          path: '/',
          domain: domainCookie
        });
        // Save company ID
        localStorage.setItem('currentCompanyId', newCompanyId);
        window.location.replace(domainRedirect);
      })
      .catch((err) => {
        console.log(err);
        M.toast({
          html: 'Tuvimos un error, intenta de nuevo más tarde',
          classes: 'error-toast'
        });
      });
  }

  function renderCompanyName(company, user, companyGroup) {
    // Return company group select if
    // 1. Company group exists with valid children
    // 2. You are an invitee
    // 3. You are admin in the parent company
    if (
      Object.keys(companyGroup).length &&
      companyGroup.childCompanies &&
      companyGroup.childCompanies.length &&
      (user.isInvitee ||
        isManagerInParentCompany(company, user, companyGroup)) &&
      companyGroup.id !== 'dWSKzHar3uSMOsKCPQ7n' // TODO hardcoded for now
    ) {
      const ret = [];

      // Handle parent company
      ret.push(
        <option key={companyGroup.parentId} value={companyGroup.parentId}>
          {companyGroup.parentName}
        </option>
      );

      // Loop through children
      for (const childCompany of companyGroup.childCompanies) {
        ret.push(
          <option key={childCompany.id} value={childCompany.id}>
            {childCompany.name}
          </option>
        );
      }

      return (
        <select
          id="company-group-select"
          defaultValue={company.id}
          onChange={(e) => {
            handleCompanychange(e.target.value);
          }}
        >
          {ret}
        </select>
      );
    }

    return <p id="c-name">{company.name}</p>;
  }

  // Get user's role
  const { authRole } = user;

  return (
    <React.Fragment>
      {/* Sidenav */}
      <div
        id="company-sidenav"
        className={classNames({
          'show-sidenav': showSidenav,
          collapsed: !sidenavNotCollapsed,
          'not-collapsed': sidenavNotCollapsed
        })}
        onMouseEnter={() => setSidenavNotCollapsed(true)}
        onMouseLeave={() => {
          if (window.innerWidth > 992) setSidenavNotCollapsed(false);
        }}
      >
        <div id="sidenav-wrapper">
          <div id="sidenav-top">
            <div id="logo-container">
              {sidenavNotCollapsed ? (
                <img
                  id="company-logo"
                  src={skin.logoBlack}
                  alt="Company logo"
                />
              ) : null}
            </div>
            <div id="company-name">
              {sidenavNotCollapsed ? (
                <>{renderCompanyName(company, user, companyGroup)}</>
              ) : null}
            </div>
          </div>
          {/* Links */}
          <div id="sidenav-inner">
            <ul id="links" onClick={() => handleSidenavClick()}>
              <li>
                <NavLink activeClassName="active" exact to="/">
                  <FontAwesomeIcon icon={['fal', 'house']} className="icon" />
                  {sidenavNotCollapsed && <p>Inicio</p>}
                </NavLink>
              </li>
              <li>
                <NavLink activeClassName="active" exact to="/mapa">
                  <FontAwesomeIcon icon={['fal', 'map']} className="icon" />
                  {sidenavNotCollapsed && <p>Mapa</p>}
                </NavLink>
              </li>
              <li>
                <NavLink activeClassName="active" to="/conductores">
                  <FontAwesomeIcon icon={['fal', 'user']} className="icon" />
                  {sidenavNotCollapsed && <p>Conductores</p>}
                </NavLink>
              </li>
              {authRole === 'admin' && (
                <li>
                  <NavLink activeClassName="active" to="/grupos">
                    <FontAwesomeIcon icon={['fal', 'users']} className="icon" />
                    {sidenavNotCollapsed && <p>Grupos</p>}
                  </NavLink>
                </li>
              )}
              {authRole === 'admin' && (
                <li>
                  <NavLink activeClassName="active" to="/viajes">
                    <FontAwesomeIcon icon={['fal', 'route']} className="icon" />
                    {sidenavNotCollapsed && <p>Viajes realizados</p>}
                  </NavLink>
                </li>
              )}
              {authRole === 'admin' && (
                <li>
                  <NavLink activeClassName="active" to="/registros">
                    <FontAwesomeIcon
                      icon={['fal', 'clipboard']}
                      className="icon"
                    />
                    {sidenavNotCollapsed && <p>Registros</p>}
                  </NavLink>
                </li>
              )}
              <li>
                <NavLink activeClassName="active" to="/vehiculos">
                  <FontAwesomeIcon icon={['fal', 'truck']} className="icon" />
                  {sidenavNotCollapsed && <p>Vehículos</p>}
                </NavLink>
              </li>
              {company.showForms && (
                <li>
                  <NavLink activeClassName="active" to="/formularios">
                    <FontAwesomeIcon
                      icon={['fal', 'clipboard-list']}
                      className="icon"
                    />
                    {sidenavNotCollapsed && <p>Formularios</p>}
                  </NavLink>
                </li>
              )}
              <li>
                <NavLink activeClassName="active" to="/ranking">
                  <FontAwesomeIcon icon={['fal', 'trophy']} className="icon" />
                  {sidenavNotCollapsed && <p>Ranking</p>}
                </NavLink>
              </li>
              <li>
                <NavLink activeClassName="active" to="/estadisticas">
                  <FontAwesomeIcon
                    icon={['fal', 'chart-mixed']}
                    className="icon"
                  />
                  {sidenavNotCollapsed && <p>Estadísticas</p>}
                </NavLink>
              </li>
              <li>
                <NavLink activeClassName="active" to="/game">
                  <FontAwesomeIcon
                    icon={['fal', 'chart-user']}
                    className="icon"
                  />
                  {sidenavNotCollapsed && <p>Mi rendimiento</p>}
                </NavLink>
              </li>
              {authRole === 'admin' && company.country === 'mex' ? (
                <li>
                  <NavLink activeClassName="active" to="/facturacion">
                    <FontAwesomeIcon
                      icon={['fal', 'money-check-dollar']}
                      className="icon"
                    />
                    {sidenavNotCollapsed && <p>Facturación</p>}
                  </NavLink>
                </li>
              ) : null}
              <li className="divider-l"></li>
              {authRole === 'admin' && (
                <li>
                  <NavLink activeClassName="active" to="/reportes">
                    <FontAwesomeIcon
                      icon={['fal', 'file-lines']}
                      className="icon"
                    />
                    {sidenavNotCollapsed && <p>Reportes de uso</p>}
                  </NavLink>
                </li>
              )}
              {authRole === 'admin' && company.reportBIUrl ? (
                <li onClick={() => registerOpenXRay()}>
                  <a
                    href={company.reportBIUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={['fal', 'chart-scatter']}
                      className="icon"
                    />
                    {sidenavNotCollapsed && <p>X-Ray</p>}
                  </a>
                </li>
              ) : null}
              <li>
                <NavLink activeClassName="active" to="/manuales">
                  <FontAwesomeIcon
                    icon={['fal', 'circle-info']}
                    className="icon"
                  />
                  {sidenavNotCollapsed && <p>Manuales</p>}
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* Black transparent courtain */}
      <div
        id="sidenav-courtain"
        className={classNames({ 'show-nc': showCourtain })}
        onClick={() => handleCourtainClick()}
      ></div>
      {/* Top dark Navbar */}
      <div id="company-navbar">
        <div
          className={classNames('burger show-on-medium-and-down', {
            open: showSidenav
          })}
          onClick={() => toggleSidenav(!showSidenav)}
        >
          <span className="burger-span"></span>
          <span className="burger-span"></span>
          <span className="burger-span"></span>
          <span className="burger-span"></span>
        </div>
        <ProfileDropdown {...props} />
        {!user.isSam && !user.isInvitee ? (
          <div id="_notifications-bell-wrapper_">
            <div
              id="notif-bell-container"
              onClick={() => setNotifOpen(!notifOpen)}
            >
              <div>
                <FontAwesomeIcon id="notif-bell" icon={['fal', 'bell']} />
                {hasUnreadNotifs ? (
                  <FontAwesomeIcon id="has-unread" icon="circle" />
                ) : null}
              </div>
            </div>
          </div>
        ) : null}
      </div>
      {/* Notifications sidenav */}
      {!user.isSam && !user.isInvitee ? (
        <SideModal isOpen={notifOpen} setIsOpen={setNotifOpen}>
          {notifOpen && (
            <CompanyNotifications
              setNotifOpen={setNotifOpen}
              history={history}
            />
          )}
        </SideModal>
      ) : null}
    </React.Fragment>
  );
}

export default CompanySidenav;
