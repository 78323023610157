import dayjs from 'dayjs';
import durationToString from './durationToString';
import roundNumber from './roundNumber';

export function sanitizeAppStatus(appStatus) {
  // if (typeof appStatus !== 'string' || typeof isSDKEnabled !== 'boolean') {
  //   return 'Inactivo';
  // }

  // if (appStatus === 'active' && isSDKEnabled) return 'Activo';
  // if (
  //   (appStatus === 'active' && !isSDKEnabled) ||
  //   (appStatus === 'inactive' && isSDKEnabled)
  // ) {
  //   return 'Pendiente';
  // }
  // if (appStatus === 'inactive' && !isSDKEnabled) return 'Inactivo';

  // First MVP to remove pending
  if (appStatus === 'active') return 'Activado';
  if (appStatus === 'inactive') return 'Desactivado';

  return;
}

export function sanitizeDay(index) {
  let ret;

  switch (index.toString()) {
    case '0':
      ret = 'Lunes';
      break;
    case '1':
      ret = 'Martes';
      break;
    case '2':
      ret = 'Miércoles';
      break;
    case '3':
      ret = 'Jueves';
      break;
    case '4':
      ret = 'Viernes';
      break;
    case '5':
      ret = 'Sábado';
      break;
    case '6':
      ret = 'Domingo';
      break;
    default:
      ret = '-';
  }
  return ret;
}

export function sanitizeDayShort(index) {
  let ret;

  switch (index.toString()) {
    case '0':
      ret = 'Dom';
      break;
    case '1':
      ret = 'Lun';
      break;
    case '2':
      ret = 'Mar';
      break;
    case '3':
      ret = 'Mie';
      break;
    case '4':
      ret = 'Jue';
      break;
    case '5':
      ret = 'Vie';
      break;
    case '6':
      ret = 'Sab';
      break;
    default:
      ret = '-';
  }
  return ret;
}

export function sanitizeDayShortest(index) {
  let ret;

  switch (index.toString()) {
    case '0':
      ret = 'D';
      break;
    case '1':
      ret = 'L';
      break;
    case '2':
      ret = 'M';
      break;
    case '3':
      ret = 'M';
      break;
    case '4':
      ret = 'J';
      break;
    case '5':
      ret = 'V';
      break;
    case '6':
      ret = 'S';
      break;
    default:
      ret = '-';
  }
  return ret;
}

export function sanitizeRouteOptimization(type) {
  let ret;

  switch (type) {
    case 'none':
      ret = 'Ninguna';
      break;
    case 'time':
      ret = 'Por tiempo';
      break;
    case 'distance':
      ret = 'Por distancia';
      break;
    default:
      ret = '-';
  }
  return ret;
}

export function phoneNumberFormatter(number) {
  const twoDigitCode = ['81', '55', '33', '56'];
  let ret = '';

  // Validate input
  if (!number) {
    return ret;
  }

  // Remove whitespaces
  number.replace(/ /g, '');

  // Check country codes
  if (number.includes('+52')) {
    // Mexico
    ret += '+52 ';
    number = number.substring(3);

    // Handle region code: https://www.codigoexactodearea.com/codigo-de-mexico/
    let region = number.substring(0, 2);
    if (twoDigitCode.includes(region)) {
      ret += `(${region}) ${number.substring(2, 6)} ${number.substring(6)}`;
    } else {
      region = number.substring(0, 3);
      ret += `(${region}) ${number.substring(3, 6)} ${number.substring(6)}`;
    }
  }
  if (number.includes('+57')) {
    // Colombia
    ret += '+57 ';
    number = number.substring(3);

    ret += number;
  }
  if (number.includes('+51')) {
    // Perú
    ret += '+51 ';
    number = number.substring(3);

    ret += number;
  }
  if (number.includes('+1')) {
    // EEUU
    ret += '+1 ';
    number = number.substring(2);

    ret += number;
  }

  return ret;
}

export function sanitizeCivilStatus(status) {
  let ret;

  switch (status) {
    case 'single':
      ret = 'Soltero/a';
      break;
    case 'consensual-union':
      ret = 'Unión libre';
      break;
    case 'married':
      ret = 'Casado/a';
      break;
    case 'divorced':
      ret = 'Divorciado/a';
      break;
    case 'widowed':
      ret = 'Viudo/a';
      break;
    default:
      ret = '-';
  }
  return ret;
}

export function sanitizeNationality(isoCode) {
  let ret;

  switch (isoCode) {
    case 'mex':
      ret = 'Mexicano/a';
      break;
    case 'other':
      ret = 'Otra';
      break;
    default:
      ret = '-';
  }
  return ret;
}

export function sanitizeGender(gender) {
  let ret;

  switch (gender) {
    case 'male':
      ret = 'Masculino';
      break;
    case 'female':
      ret = 'Femenino';
      break;
    case 'other':
      ret = 'Otro/a';
      break;
    default:
      ret = '-';
  }
  return ret;
}

export function logDescription(log) {
  // Log type 'parada'
  if (log.type === 'parada') {
    // This is for old app versions
    if (log.message) return log.message;
  }

  // Log type 'alerta'
  if (log.type === 'alerta') {
    // Return log message
    if (log.message) return log.message;
  }

  // Gas
  if (log.type === 'gas') {
    let gasType = 'Diésel';

    // App version >= 0.13.15 stores the information in gas_details
    // App version < 0.13.15 stores the information in metadata
    if (log.gas_details) {
      // Handle gas type
      if (log.gas_details.type === 'green') {
        gasType = 'Magna';
      } else if (log.metadata.type === 'red') {
        gasType = 'Premium';
      } else if (log.metadata.type === 'gas') {
        gasType = 'Gas';
      }

      return `$${roundNumber(log.gas_details.cost, 1)} por ${roundNumber(
        log.gas_details.liters,
        1
      )} litros de ${gasType}`;
    } else {
      // Handle gas type
      if (log.metadata.type === 'green') gasType = 'Magna';
      else if (log.metadata.type === 'red') gasType = 'Premium';

      return `$${roundNumber(log.metadata.cost, 1)} por ${roundNumber(
        log.metadata.liters,
        1
      )} litros de ${gasType}`;
    }
  }

  // Telemetry
  if (log.type === 'telemetry') {
    if (log.metadata.type === 'speeding') {
      // Check if log has custom speed limit to show more info
      // if (log.hasCustomSpeedLimit) {
      //   return `Velocidad: ${Math.round(
      //     log.position.spd
      //   )} km/h <br/> Límite empresa: ${Math.round(
      //     log.metadata.speedLimit
      //   )} km/h <br/> Límite legal: ${Math.round(
      //     log.metadata.legalSpeedLimit
      //   )}`;
      // } else {
      //   return `Velocidad: ${Math.round(
      //     log.position.spd
      //   )} km/h <br/> Límite: ${Math.round(log.metadata.speedLimit)} km/h`;
      // }
      return `Detectada: ${Math.round(
        log.position.spd
      )} km/h <br/> Límite: ${Math.round(log.metadata.speedLimit)} km/h`;
    } else if (log.metadata.type === 'acc') {
      return `${roundNumber(log.metadata.value, 2)} m/s2`;
    } else if (log.metadata.type === 'deacc') {
      return `${roundNumber(log.metadata.value, 2)} m/s2`;
    } else if (log.metadata.type === 'cornering') {
      if (log.metadata.value) {
        return `${roundNumber(log.metadata.value, 2)} m/s2`;
      }
    }
  }

  // Incoming and outgoing calls
  if (log.type === 'llamada_entrante' || log.type === 'llamada_saliente') {
    if (log.call_details) {
      const startDate = dayjs(log.call_details.start);
      const endDate = dayjs(log.call_details.end);
      const diff = endDate.diff(startDate);

      return durationToString(dayjs.duration(diff), true);
    }
  }

  // Deliveries
  if (log.type === 'entrega') {
    // If wasDeliverySuccess, we can determine between a good and bad delivery
    if ('wasDeliverySuccess' in log) {
      if (log.wasDeliverySuccess) {
        return 'Exitosa';
      } else {
        let dRet = 'No exitosa';
        if (log.deliveryFailureReason) {
          dRet += `: ${log.deliveryFailureReason}`;
        }
        return dRet;
      }
    }
  }

  return '';
}

export function scoresSanitizer(type) {
  if (type === 'acceleration') return 'Aceleración';
  if (type === 'braking') return 'Frenado';
  if (type === 'cornering') return 'Curvas';
  if (type === 'speeding') return 'Velocidad';
  if (type === 'phoneDistraction') return 'Uso de celular';
  if (type === 'tyres') return 'Llantas';
  if (type === 'brakes') return 'Frenos';
  if (type === 'fuel') return 'Combustible';
  if (type === 'overall') return 'General';

  return '';
}
