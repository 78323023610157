import React, { useState } from 'react';
import './PhoneInput.scss';

function PhoneInput(props) {
  const { phone, setPhone, label } = props;
  const [countryCode, setCountryCode] = useState('+52');
  const [phoneCopy, setPhoneCopy] = useState(phone);

  return (
    <div className="input-field _phone-input_">
      <label className="active">{label}</label>
      <select
        className="phone-input-country-code"
        value={countryCode}
        onChange={(e) => {
          // Set country code
          setCountryCode(e.target.value);
          // Update phone number
          setPhone(`${e.target.value}${phoneCopy}`);
        }}
      >
        <option value="+52">Mex +52</option>
        <option value="+57">Col +57</option>
        <option value="+51">Per +51</option>
        <option value="+1">EEUU +1</option>
      </select>
      <input
        className="phone-input-number"
        type="text"
        value={phoneCopy}
        onChange={(e) => {
          if (e.target.value) {
            // Add country code to number
            setPhone(`${countryCode}${e.target.value}`);
            // Save copy for when country code changes
            setPhoneCopy(e.target.value);
          } else {
            // Reset values
            setPhone('');
            setPhoneCopy('');
          }
        }}
      />
    </div>
  );
}

export default PhoneInput;
