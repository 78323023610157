import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import React, { memo } from 'react';
import ReactTooltip from 'react-tooltip';
import { BillableTooltip } from './BillableTooltip';
dayjs.extend(localizedFormat);
export const BillingItem = memo(function BillingItem({
  user,
  idx,
  loginHistory,
  billable,
  proratable,
  deletable
}) {
  const today = dayjs();
  return (
    <tr>
      <td className="driver-name">
        <span className="pos-index">{idx + 1}.</span>
        <span>{user.fullName}</span>
      </td>
      <td className="center-align first-login">
        <span>
          {loginHistory.length > 0
            ? dayjs(loginHistory[0].created).format('DD/MM/YY LT')
            : user.firstLoginDate
            ? dayjs(user.firstLoginDate).format('DD/MM/YY LT')
            : '-'}
        </span>
      </td>
      <td className="center-align billable-driver">
        <span
          className={classNames('status', {
            'green-s': billable || user.billable,
            'yellow-s': deletable,
            'red-s': !billable && !user.billable && !deletable
          })}
        >
          <BillableTooltip
            billable={billable}
            deletable={deletable}
            loginHistory={loginHistory}
            today={today}
            user={user}
          />
        </span>
      </td>
      <td className="center-align proratable-driver">
        <span>
          {proratable || user.proratable ? (
            <>
              Prorrateo{' '}
              <FontAwesomeIcon
                icon="question-circle"
                data-tip={`${
                  user.proratable
                    ? user.proratable
                    : today.daysInMonth() - proratable
                } días`}
              />
              <ReactTooltip place="bottom" type="dark" effect="solid" />
            </>
          ) : billable || user.billable ? (
            <>
              Completo{' '}
              {user.deleted && (
                <>
                  <FontAwesomeIcon
                    icon="question-circle"
                    data-tip={'El usuario inició sesión por al menos 72 horas'}
                  />
                  <ReactTooltip place="bottom" type="dark" effect="solid" />
                </>
              )}
            </>
          ) : (
            '-'
          )}
        </span>
      </td>

      <td className="center-align deleted-driver">
        <span
          className={classNames('status', {
            'red-s': user.deleted
          })}
        >
          {user.deleted ? 'Si' : '-'}
        </span>
      </td>
      <td className="center-align deleted-date">
        <span>
          {user.deleted ? dayjs(user.deletedDate).format('DD/MM/YY LT') : '-'}
        </span>
      </td>
    </tr>
  );
});
